/* Container to hold both sections */
.home-container {
    display: flex;
    flex-direction: column;
  }
  
  
  
  /*================ Stats Section =================*/
  
  /* Container for the entire stats section */
  .stats-section-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 60px 20px;
  }
  
  /* Title for the section */
  .stats-title {
    font-size: 2.5rem;
    margin-bottom: 40px;
    color: #393939; /* Secondary color */
  }
  
  /* Stats Blob Container */
  .stats-blobs {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
    margin-bottom: 40px;
  }
  
  /* Individual Stat Box */
  .stat-box {
    background-color: var(--color-primary);
    color: white;
    padding: 40px;
    border-radius: 22px;
    width: 200px;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    transition: var(--transition);
  }
  
  .stat-box:hover {
    transform: scale(1.1);
    box-shadow: 0 4px 8px rgba(42, 42, 42, 0.4); /* Adjust shadow to match the design */
  }
  
  /* Stat title and underline */
  .stat-title-container {
    text-align: center;
    margin-bottom: 15px;
  }
  
  .stat-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .stat-underline {
    width: 50px;
    height: 2px;
    background-color: white;
    margin: 0 auto;
  }
  
  /* Stat value (number) */
  .stat-value {
    font-size: 2.5rem;
    font-family: 'Manjari', sans-serif;
    font-weight: 400;
  }
  
  /* Paragraph under the stats section */
  .stats-paragraph {
    max-width: 600px;
    font-size: 1.2rem;
    text-align: center;
    color: var(--color-secondary);
  }


  .text-secondary {
    color: var(--color-secondary);
  }
  
/* Who We Are Section */
.who-we-are-section {
  padding: 2rem 1rem;
  background-color: rgba(241, 241, 241, 0.5); /* Adjust background color if needed */
  z-index: 0;

}

.who-we-are-container {
  max-width: 1200px;
  margin: 0 auto;
}

.who-we-are-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align items to the start by default */
  gap: 2rem;
  margin-top: 4rem;
  margin-bottom: 4rem;
}

/* Text Section */
.who-we-are-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align text to the left by default */
  margin-right: 50px;
}

.who-we-are-text > * + * {
  margin-top: 1rem; /* Adds spacing between h2, p, and button */
}

.who-we-are-title {
  font-size: 2rem;
  font-weight: bold;
  color: var(--color-secondary);
}

.who-we-are-description {
  color: var(--color-secondary);
}

/* Swiper Styles */
.who-we-are-swiper .swiper-container {
  width: 100%;
  height: 400px;
}

.swiper-slide-content {
  position: relative;
  width: 100%;
  height: 100%;
}

.swiper-slide-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 22px;
}

/* Swiper Pagination Styles */
.who-we-are-swiper .swiper-pagination-bullet {
  background-color: var(--color-primary);
  opacity: 0.5;
}

.who-we-are-swiper .swiper-pagination-bullet-active {
  background-color: var(--color-primary);
  opacity: 1;
}

/* Swiper Navigation Styles */
.who-we-are-swiper .swiper-button-next,
.who-we-are-swiper .swiper-button-prev {
  color: var(--color-primary);
}

/* Optional: Adjust the size of the navigation arrows */
.who-we-are-swiper .swiper-button-next::after,
.who-we-are-swiper .swiper-button-prev::after {
  font-size: 40px; /* Adjust as needed */
}

/* Displacement Slider Styles */
#slider {
  position: relative;
  width: 100%;
  height: 150vh; /* Full viewport height */
  overflow: hidden;
}

#slider canvas {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
}

#slider img {
  display: none; /* Hide the image tags */
}

.slider-inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

#slider-content {
  color: white;
  text-align: left;
  padding: 20px;
  max-width: 800px;
}

#slider-content .meta {
  font-family: 'Nunito sans', sans-serif;
  font-size: 14px;
  letter-spacing: 2px;
  color: var(--color-primary);
  text-transform: uppercase;
  margin-top: 30px;
}

#slider-content h2 {
  font-size: 3rem;
  line-height: 1.7;
  margin: 0;
  font-weight: 400;
  font-family: 'Manjari', sans-serif;
}

#slider-content .hidden {
  display: none;
}

#slide-status {
  font-weight: 400;
  font-family: 'Manjari', sans-serif;
  font-size: 1.5rem;
  margin-top: 5px;
  
}

#slide-title {
  color: var(--color-tertiary);
}

#pagination {
  position: absolute;
  top: 50%;
  right: 30px;
  transform: translateY(-50%);
  z-index: 2;
}

#pagination button {
  display: block;
  border: none;
  background-color: rgba(255, 255, 255, 0.3);
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin: 10px 0;
  cursor: pointer;
  outline: none;
}

#pagination button.active {
  background-color: rgba(255, 255, 255, 1);
}

#pagination button:hover {
  background-color: rgba(255, 255, 255, 0.6);
}


/* Product Categories Section */

.product-categories {
  padding-top: 6rem;
  padding-bottom: 3rem;
}

.product-categories h2 {
  font-size: 1.875rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 2rem;
  color: var(--color-secondary);
}

.category-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1.5rem;
}

/* Category card styles */
.category-card {
  background-color: #ffffff;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  transition: var(--transition);
}

.category-card:hover {
  transform: scale(1.05);
}

.category-card img {
  width: 100%;
  height: 12rem;
  object-fit: cover;
}

.category-content {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.category-content h3 {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  color: var(--color-secondary);
}

.category-content p {
  color: var(--color-secondary);
  margin-bottom: 1rem;
  flex-grow: 1;
}

.category-content .btn {
  width: fit-content;
}

/* Catalog Download Section */
.catalog-download {
  text-align: center;
  margin-top: 70px;
}

.catalog-download p {
  margin-bottom: 10px;
  font-size: 1.1rem;
  color: var(--color-secondary); /* Use your secondary color variable */
}

.catalog-download .btn {
  margin-top: 10px;
  width: fit-content;
}


/* Responsive styles for category cards */
@media (min-width: 640px) {
  .category-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 768px) {
  .category-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 1024px) {
  .category-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}


.blog-section {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.blog-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;  
  margin-bottom: 100px;
}

/* Blog card styles */
.blog-card {
  border-radius: 22px;
  overflow: hidden;
  padding: 1rem;
  width: 90%; /* Reduce width to 90% of its container */
  max-width: 400px; /* Set a maximum width */
  margin: 0 auto; /* Center the card horizontally */
}

.blog-card img {
  width: 100%;
  height: 12rem;
  border-radius: 22px;
  
}

.blog-card-content {
  padding: 1.5rem;
}

.blog-card h3 {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  color: var(--color-secondary);
}

.blog-card p {
  color: var(--color-secondary);
  margin-bottom: 1rem;
}

/* Blog content styles */
.blog-content h2 {
  font-size: 1.875rem;
  font-weight: 700;
  margin-bottom: 1rem;
  color: var(--color-secondary);
  text-align: center;
  padding-top: 50px;
}

.blog-content p {
  color: var(--color-secondary);
  margin-bottom: 1.5rem;
  text-align: center;

}



.blog-content .blog-button {
  display: block;
  margin: auto;
  width: fit-content;
}


/* Responsive styles */
@media (min-width: 768px) {
  .blog-container {
    flex-direction: row;
    align-items: flex-start;
  }

  .blog-card,
  .blog-content {
    width: 50%;
    
  }

  
}

  
  
@media (min-width: 1024px) {
  .who-we-are-content {
    flex-direction: row;
    align-items: flex-start;
  }
  .who-we-are-text,
  .who-we-are-swiper {
    width: 50%;
  }
  .who-we-are-text {
    margin-right: 0px;
  }

}

/* For tablets and mobile devices */
@media (max-width: 1024px) {
  .who-we-are-content {
    flex-direction: column;
    align-items: center; /* Center items on smaller screens */
    text-align: center;  /* Center text */
  }
  .who-we-are-text {
    align-items: center; /* Center align text */
  }
  .who-we-are-swiper {
    width: 100%;
  }
  .who-we-are-swiper .swiper-container {
    width: 100%;
    height: auto;    /* Allow height to adjust automatically */
    margin-left: 0;
  }
}


  
  
  /* Responsive design for tablets and mobile devices */
  @media (max-width: 768px) {
    .hero-container {
      padding: 20px;
      background-size: cover;
      background-position: center;
    }
  
    .hero-title {
      font-size: 1.8rem;
    }
  
    .hero-description {
      font-size: 1rem;
    }
  
    .hero-logo {
      max-width: 150px;
    }
    .who-we-are-text {
      margin-right: 0px;
    }

    .blog-content h2, .blog-content p {
      text-align: center;
    }

    .blog-button {
      margin: 0 auto;
    }

    .blog-card {
      padding: 0.5rem;
    }
    .blog-content p {
      font-size: 1.1rem;
    }

  }
  
  @media (max-width: 480px) {
    .hero-title {
      font-size: 1.5rem;
    }
  
    .hero-description {
      font-size: 0.9rem;
    }
  
    .hero-logo {
      max-width: 120px;
    }

    .who-we-are-title {
      font-size: 1.5rem;
    }
    .who-we-are-description {
      font-size: 1rem;
    }

    
  }

/*========================= History Section ========================*/

.about-section {
    max-width: 1200px;
    margin: 0 auto;
    padding: 3rem 1rem;
  }
  
  .about-title {
    font-weight: bold;
    text-align: center;
    margin-bottom: 3rem;
    color: var(--color-secondary);
  }
  
  .about-content {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  
  .about-text {
    flex: 1;
    color: var(--color-secondary);
  }
  
  .about-image {
    flex: 1;
  }
  
  .about-image img {
    width: 100%;
    height: auto;
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  @media (min-width: 768px) {
    .about-section {
      padding: 6rem 2rem;
    }
  
    .about-title {
      font-size: 3rem;
    }
  
    .about-content {
      flex-direction: row;
      align-items: flex-start;
    }
  
    .about-text {
      padding-right: 2rem;
    }
  }
  
  @media (min-width: 1024px) {
    .about-title {
      font-size: 3.5rem;
    }
  }



  /*========================= Mission/Vision/Values Section ========================*/

  .card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
    padding: 20px;
    margin-top: 2rem;
    margin-bottom: 7rem;
  }

  .card-text-mission {
    color: var(--color-secondary);
    text-align: center;
    margin: 0 auto;
    padding-bottom: 4rem;
  }
  
  .card {
    background-color: white;
    border-radius: 22px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.25);
    padding: 30px;
    width: 100%;
    transition: var(--transition);
  }
  
  .card:hover {
    transform: scale(1.05);
  }
  
  .card-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 40px;
    color: var(--color-secondary);
    text-align: center;

  }
  
  .card-text {
    margin-bottom: 50px;
    color: var(--color-secondary);
    text-align: center;
  }
  
  .icon-wrapper {
    display: flex;
    justify-content: center;
  }
  
  .card-icon {
    width: 80px;
    height: 80px;
    transition: var(--transition);
    margin-bottom: 2rem;
  }
  
  .card-icon:hover {
    filter: brightness(50%);
  }
  
  @media (min-width: 768px) {
    .card {
      width: calc(50% - 10px);
    }
  }
  
  @media (min-width: 1024px) {
    .card {
      width: calc(33.333% - 13.333px);
    }
  }
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;800&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.hero-title-h1 {
  color: var(--color-white);
}


.products-section{
  font-weight: 200;
  padding-block: min(20vh, 6rem);
  text-align: center;
  margin-inline: auto;
  position: relative;
}

section span,
section p,
section h2,
section h3 {
  letter-spacing: 0.035rem;
}

section span {
  text-transform: uppercase;
  display: block;
  font-size: 1.2rem;
  color: var(--color-secondary);
}

section h2 {
  font-size: clamp(3.5rem, 3rem + 1.6vw, 5rem);
  margin-top: -0.625rem;
  color: var(--color-secondary);
}

section p {
  line-height: 1.6;
  color: var(--color-secondary);
  max-width: 50rem;
  margin: 0 auto;
  font-size: clamp(0.9rem, 0.825rem + 0.3vw, 1.2rem);
}

.bg-watermark {
  color: var(--color-tertiary);
  font-size: clamp(6rem, 1.3333rem + 14.9333vw, 11rem);
  font-weight: 800;
  position: absolute;
  z-index: -1;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  user-select: none;
  letter-spacing: 0.5rem;
  width: 100%;
  text-align: center;
  word-break: break-word;
}

.products-cards {
  margin-top: 7rem;
  display: grid;
  grid-template-columns: 1fr; /* Default to one column */
  gap: 2rem;
  
}


.category-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem; /* Space between buttons */
  margin: 2rem 0; /* Space above and below the buttons */
  margin-bottom: 4rem;
}

@media screen and (min-width: 600px) {
  .products-cards {
    grid-template-columns: repeat(2, 1fr); /* Two columns on larger screens */
    gap: 2rem;
  }
}

.products-card {
  position: relative;
  cursor: pointer;
  border-radius: 22px;
}

.products-card h3 {
  text-transform: capitalize;
  font-size: clamp(1rem, 0.9167rem + 0.2667vw, 1.25rem);
  font-weight: 400;
  color: var(--color-white);
  margin: 0; /* Remove any default margins */
  align-self: flex-start; /* Aligns the h3 to the top */
}

.products-card .btn {
  align-self: flex-end;
}

.products-card::before {
  position: absolute;
  content: "";
  width: 5rem;
  height: 6.25rem;
  z-index: 0;
  transition: 0.3s ease-in-out;
  background: linear-gradient(to left, var(--color-primary), #f2709c);
  top: -0.375rem;
  left: -0.375rem;
  border-radius: 5px;
  
}

.products-card::after {
  position: absolute;
  inset: 0;
  content: "";
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    hsla(210, 3%, 26%, 0.1),
    hsla(0, 0%, 3%, 0.6)
  );
  border-radius: 5px;
}

.products-card img {
  max-width: 100%;
  display: block;
  filter: grayscale(100%);
  transition: 0.5s ease;
  border-radius: 5px;}

.products-card-content {
  position: absolute;
  inset: 0; /* This replaces top, right, bottom, left: 0 */
  z-index: 99;
  color: var(--color-secondary);
  width: 100%;
  padding: 1.875rem 1.25rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* This will push the h3 to the top and the button to the bottom */
}

.products-card:hover img {
  filter: grayscale(0%);
}

.products-card:hover::before {
  transform: scale(1.03);
}

body {
  background: var(--color-white);
  min-height: 100vh;
}


/* src/css/productCatalog.css */

.product-catalog-section {
  padding: 0rem;

}

.product-catalog-section h2 {
  text-align: center;
}

.product-catalog-cards {
  display: grid;
  grid-template-columns: 1fr; /* Default to one column */
  gap: 2rem;
  justify-items: center; /* Center the items horizontally */
  margin-top: 20px;
}

@media screen and (min-width: 768px) {
  .product-catalog-cards {
    grid-template-columns: repeat(2, 1fr); /* Two columns */
  }

}

@media screen and (min-width: 1024px) {
  .product-catalog-cards {
    grid-template-columns: repeat(3, 1fr); /* Three columns */
  }
}


.product-catalog-card {
  width: 90%;
    max-width: 300px; /* Optional: set a max width for cards */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: var(--transition);
    height: fit-content;
    border-radius: 22px;
  }

  
.product-catalog-card:hover {
  transform: scale(1.05);
  }

.product-image-container {
  width: 100%;
  overflow: hidden;
}

.product-image-container img {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 22px 22px 0px 0px;
}

.product-catalog-card-content {
  padding: 1.5rem;
}

.product-catalog-card h3 {
  margin-top: 0;
  color: var(--color-secondary);
}

.product-catalog-card h4 {
  color: var(--color-primary);
  margin: 0.5rem 0;
}

.product-catalog-card p {
  color: var(--color-secondary);
  padding-bottom: 1rem;
}











/* ================== Product Detail ================== */

/* Wrapper for the product detail section */
.product-detail-section {
  padding-top: 10rem;
}

/* Container for product detail content */
.product-detail {
  width: var(--form-width);
  background: var(--color-tertiary);
  margin-inline: auto;
  border-radius: 22px;
  padding: 2rem;
}

/* Product detail buttons (Edit, Delete) */
.product-detail-buttons {
  display: flex;
  gap: 1rem;
  align-items: center;
}

/* Product images */
.product-detail-images {
  margin: 1rem 0;
  max-height: 30rem;
  overflow: hidden;
  border-radius: 22px;
}

.product-detail-images img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

/* Back to products button */
.product-detail-btn {
  margin-top: 3rem;
  text-align: center;
  align-items: center;
  align-self: center;
}


.product-detail h3 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.product-detail h4 {
  color: var(--color-primary);
  padding-bottom: 1rem;
}

/* ================== Create & Edit Product ================== */

/* Remove the fixed height and adjust padding */
.create-product, .edit-product {
  padding-top: 70px;
  padding-bottom: 70px; /* Add bottom padding */
  width: 50%;
  margin-inline: auto;
  /* Remove the fixed height */
  display: grid;
  place-items: center;
}

.create-product h2, .edit-product h2 {

  font-size: 3rem;
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
}




/* ================== Product Dashboard ================== */

.dashboard {
  padding-top: 3rem;
}

.dashboard-container {
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
}

.dashboard-product {
  display: flex;
  align-items: center;
  gap: 1.5rem; /* Increased gap for better spacing between elements */
  background: var(--color-secondary-transparent);
  padding: 1rem 2rem; /* Increased padding for better aesthetics */
  border-radius: 22px;
  box-shadow: 4px 5px 2px rgba(0, 0, 0, 0.1);
  transition: var(--transition);
  color: var(--color-white);
  width: 100%; /* Ensures the product takes full width of the container */
  max-width: 1000px; /* Limits the maximum width for better readability */
  margin: 0 auto; /* Centers the product within the container */
}


.dashboard-product:hover {
  transform: scale(1.01);
}

.dashboard-product-info {
  display: flex;
  align-items: center; /* Vertically centers the thumbnail and title */
  gap: 1.5rem;
  width: 100%;
}

.dashboard-product-info h4 {
  color: var(--color-white);
  margin: 0; /* Removes default margin */
  font-size: 1.2rem; /* Adjusts font size for better visibility */
  text-align: left; /* Aligns text to the left for readability */
  flex: 1; /* Allows the title to take up available space */
}


.dashboard-product-thumbnail {
  width: 80px; /* Adjusted width for better alignment */
  height: 80px; /* Maintains a square aspect ratio */
  border-radius: 20px; /* Makes the thumbnail circular */
  overflow: hidden;
  flex-shrink: 0; /* Prevents the thumbnail from shrinking */
}

.dashboard-product-thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the container without distortion */
}


.dashboard-product-actions {
  display: flex;
  gap: 1rem;
}






/* ================== Responsive Design ================== */

/* Tablet */
@media screen and (max-width: 1100px) {
  .product-catalog-cards {
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    align-items: center;
    align-self: center;
  }

  .product-detail {
    width: 100%;
    padding: 2rem;
  }

  .product-detail-buttons {
    width: 40%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .create-product, .edit-product {
    width: 100%;
  }

  .btn-submit {
    width: 50%;
  }

  .dashboard-product {
    flex-direction: column;
    padding: 2rem;
  }

  .dashboard-product-info {
    gap: 1.5rem;
  }

  .dashboard-product-thumbnail {
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
  }
}

/* Mobile */
@media screen and (max-width: 800px) {
  .product-catalog-cards {
    grid-template-columns: 1fr;
    gap: 2rem;
    width: auto;
  }

  .product-detail {
    width: 100%;
  }

  .product-detail-buttons {
    width: 40%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .create-product, .edit-product {
    width: 100%;
  }

  .btn-submit {
    width: 50%;
  }

  .dashboard-product {
    flex-direction: column;
    padding: 0rem;
  }

  .dashboard-product-info {
    gap: 1.5rem;
    flex-direction: column;
  }

  .dashboard-product-info h4 {
    font-size: 0.9rem;
    text-align: center;
    padding: 10px;
  }

  .dashboard-product-thumbnail {
    width: 100%;
    height: 100%;
    border-radius: 22%;
    padding: 10px;
  }

  .dashboard-product-actions .btn {
    padding: 10px 10px 10px 10px;
    margin-bottom: 20px;
  }
}






/* src/css/products.css */

/* Existing styles... */

/* Loading Message */
.loading-message {
  text-align: center;
  font-size: 1.2rem;
  margin-top: 2rem;
  color: var(--color-secondary);
}

/* No Products Found Message */
.no-products-message {
  text-align: center;
  font-size: 1.2rem;
  margin-top: 2rem;
  color: var(--color-primary);
}

/* Ensure Category Buttons are properly spaced */
.category-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 2rem;
}

/* Optional: Adjust product-catalog-section for better spacing */
.product-catalog-section {
  padding: 2rem 1rem;
}

/* Ensure proper alignment on smaller screens */
@media screen and (max-width: 1100px) {
  .category-buttons {
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (max-width: 800px) {
  .category-buttons {
    flex-direction: column;
    align-items: center;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Manjari:wght@100;400&family=Nunito+Sans:wght@700&display=swap');


* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  text-decoration: none;
  list-style: none;
  box-sizing: border-box;
}

:root {
  --color-primary: #ED205A;
  --color-secondary: #393939;
  --color-tertiary: #F5F5F5;
  --color-complementary: #D9D9D9;
  --color-white: #FFFFFF;
  --color-background: #FFFFFF;
  --color-primary-transparent: rgba(237, 32, 90, 0.7);
  --color-primary-transparent-sidebar: rgba(237, 32, 90, 0.25);
  --color-primary-transparent-sidebar-hover: rgba(237, 32, 90, 0.35);
  --color-secondary-transparent: rgba(57, 57, 57, 0.9);
  --color-secondary-transparent-sidebar: rgba(57, 57, 57, 0.2);
  --color-tertiary-transparent: rgba(245, 245, 245, 0.7);
  --color-complementary-transparent: rgba(217, 217, 217, 0.7);
  --color-white-transparent: rgba(255, 255, 255, 0.7);
  --color-background-transparent: rgba(255, 255, 255, 0.7);


  --container-width-lg: 86%;
  --container-width-md: 90%;
  --container-width-sm: 94%;
  --form-width: 50%;

  --transition: all 400ms ease;
}



/* Global styles */
/* src/index.css or src/App.css */

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  position: relative;
  top: 0 !important;
}

body {
  transition: filter 0.3s ease;
}



/* Theme Classes applied to .theme-container */
.theme-container.normal {
  filter: none;
}

.theme-container.light-blue {
  filter: invert(0) hue-rotate(220deg) brightness(0.9) saturate(0.6);
}

.theme-container.yellowish-beige {
  filter: invert(0) hue-rotate(70deg) brightness(1) saturate(1.6);
}

.theme-container.green {
  filter: invert(0) hue-rotate(190deg) brightness(1) saturate(0.9);
}

/* Transition for smooth theme change */
.theme-container {
  transition: filter 0.3s ease;
}





.App {
  position: relative;
  z-index: 0; /* Ensures main content is above the background animation */
}


/* Optional: Adjust z-index if needed */



.container {
  width: var(--container-width-lg);
  margin: 0 auto;
}
/* Heading Styles */
h1, h2, h3 {
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 700; /* Bold */
}

h1 {
  font-size: 2.5rem; /* Adjust based on your design */
}

h2 {
  font-size: 2rem; /* Smaller than h1 */
}

h3 {
  font-size: 1.5rem; /* Smaller than h2 */
}

/* Manjari Regular for h4 */
h4 {
  color: var(--color-primary);
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 400; /* Regular */
  font-size: 1.2rem; /* Adjust size */
}

/* Manjari Thin for Paragraphs */
p {
  font-family: 'Manjari', sans-serif;
  font-weight: 100; /* Thin */
  font-size: 1rem; /* Adjust size */
}


img {
  width: 100%;
  display: block;
  object-fit: cover;
  transition: var(--transition);
}

img:hover {
  transform: scale(1.02);
}


.content-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
}

/* Other container styles */
.container {
  width: var(--container-width-lg);
  margin: 0 auto;
}

/* Other CSS remains the same... */



.btn {
  display: inline-block;
  background: var(--color-secondary);
  color: var(--color-background);
  padding: 0.33rem 1.4rem;
  border: 3px solid transparent;
  border-radius: 112px;
  font-weight: 400;
  font-family: 'Nunito Sans', sans-serif;
  transition: var(--transition);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5); /* Adjust shadow to match the design */
  text-align: center;
}

.btn:hover {
  background: var(--color-background);
  color: var(--color-secondary);
  border-color: var(--color-tertiary);
}

.btn-primary {
  background: var(--color-primary);
  color: var(--color-background);
}

.btn-secondary {
  background: var(--color-primary);
  color: var(--color-background);
}

.btn-background {
  background: var(--color-background);
  color: var(--color-white);
}







/* ====================== Error Page =============================== */

.center {
  text-align: center;
  padding-top: 15rem;
  margin-inline: center;
  display: block;
  width: 100%;
}

.center-p {
  text-align: center;
  padding: 2rem;
}

.error-page h3{
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-size: 2rem;
}

/* ====================== Loader =============================== */

.loader {
  position: relative;
  top:0;
  left:0;
  height:100vh;
  width:100vw;
  display: grid;
  place-items: center;
  opacity: 70%;
}



/* ====================== Hero =============================== */

/* Hero section container */
.hero-container-home {
  background-image: url('../public/assets/Homepage - Hero.jpg'); /* Replace with your background image path */
  background-size: cover;
  background-attachment: fixed; /* Fix the background to create parallax effect */
  background-position: center;
  min-height: 100vh; /* Ensure it covers the full viewport height */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
}

.hero-container-about {
  background-image: url('../public/assets/About - Hero.jpg'); /* Replace with your background image path */
  background-size: cover;
  background-attachment: fixed; /* Fix the background to create parallax effect */
  background-position: center;
  min-height: 100vh; /* Ensure it covers the full viewport height */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
}

.hero-container-contact {
  background-image: url('../public/assets/Contact - Hero.jpg'); /* Replace with your background image path */
  background-size: cover;
  background-attachment: fixed; /* Fix the background to create parallax effect */
  background-position: center;
  min-height: 100vh; /* Ensure it covers the full viewport height */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
}

.hero-container-blog {
  background-image: url('../public/assets/Blog - Hero.jpg'); /* Replace with your background image path */
  background-size: cover;
  background-attachment: fixed; /* Fix the background to create parallax effect */
  background-position: center;
  min-height: 100vh; /* Ensure it covers the full viewport height */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
}

.hero-container-products {
  background-image: url('../public/assets/Products - Hero.jpg'); /* Replace with your background image path */
  background-size: cover;
  background-attachment: fixed; /* Fix the background to create parallax effect */
  background-position: center;
  min-height: 100vh; /* Ensure it covers the full viewport height */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
}

.hero-container-products-dairy {
  background-image: url('../public/assets/Homepage - Hero.jpg'); /* Dairy */
  background-size: cover;
  background-attachment: fixed; /* Fix the background to create parallax effect */
  background-position: center;
  min-height: 100vh; /* Ensure it covers the full viewport height */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
}

.hero-container-products-ice-cream {
  background-image: url('../public/assets/Product - Ice Cream.jpg'); /* Ice Cream */
  background-size: cover;
  background-attachment: fixed; /* Fix the background to create parallax effect */
  background-position: center;
  min-height: 100vh; /* Ensure it covers the full viewport height */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
}

.hero-container-products-pastry {
  background-image: url('../public/assets/Product - Pastry.jpg'); /* Pastry */
  background-size: cover;
  background-attachment: fixed; /* Fix the background to create parallax effect */
  background-position: center;
  min-height: 100vh; /* Ensure it covers the full viewport height */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
}

.hero-container-products-bakery {
  background-image: url('../public/assets/Product - Bakery.jpg'); /* Bakery */
  background-size: cover;
  background-attachment: fixed; /* Fix the background to create parallax effect */
  background-position: center;
  min-height: 100vh; /* Ensure it covers the full viewport height */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
}

.hero-container-products-packaging {
  background-image: url('../public/assets/Product - Packaging.jpg'); /* Packaging */
  background-size: cover;
  background-attachment: fixed; /* Fix the background to create parallax effect */
  background-position: center;
  min-height: 100vh; /* Ensure it covers the full viewport height */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
}
.hero-container-products-equipment {
  background-image: url('../public/assets/Product - Equipment.jpg'); /* Packaging */
  background-size: cover;
  background-attachment: fixed; /* Fix the background to create parallax effect */
  background-position: center;
  min-height: 100vh; /* Ensure it covers the full viewport height */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
}

.hero-container-products-nuts {
  background-image: url('../public/assets/Product - Nuts.jpg'); /* Packaging */
  background-size: cover;
  background-attachment: fixed; /* Fix the background to create parallax effect */
  background-position: center;
  min-height: 100vh; /* Ensure it covers the full viewport height */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
}

.hero-container-products-all {
  background-image: url('../public/assets/Products - Hero.jpg'); /* All Products */
  background-size: cover;
  background-attachment: fixed; /* Fix the background to create parallax effect */
  background-position: center;
  min-height: 100vh; /* Ensure it covers the full viewport height */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
}

.hero-content {
  max-width: 600px; /* Limit the width for better readability */
  margin: 0 auto;
}

.hero-logo {
  max-width: 200px;
  margin-bottom: 20px;
  margin: 0 auto;
}

.hero-title {
  font-size: 2rem;
  margin-bottom: 15px;
  color: #fff;
}

.hero-title-h1 {
  font-size: 2.8rem;
}

.hero-description {
  font-size: 1.1rem;
  margin-bottom: 30px;
  color: #fff;
}


/* Hide the default checkbox appearance */
.custom-checkbox-container input[type="checkbox"] {
  -webkit-appearance: none; /* Safari and Chrome */
  -moz-appearance: none;    /* Firefox */
  appearance: none;
  width: 20px;
  height: 35px;
  margin: 0;
  cursor: pointer;
  position: relative;
  outline: none;
  border: 1.5px solid var(--color-primary); /* Use your primary color */
  border-radius: 50%; /* Make it round */
  background-color: #fff;
  transition: var(--transition);
}

/* Style the checked state */
.custom-checkbox-container input[type="checkbox"]:checked {
  background-color: var(--color-primary); /* Fill background when checked */
}

/* Optional: Add a checkmark or dot inside the checkbox when checked */
.custom-checkbox-container input[type="checkbox"]:checked::after {
  content: '✔';
  position: absolute;
  top: 6px;
  left: 10px;
  width: 1px;
  height: 12px;
  color: #fff; /* Color of the inner dot */
  border-radius: 50%;

  transition: var(--transition);
}

/* Adjust the label */
.custom-checkbox-container label {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #000;
  cursor: pointer;
}

/* Ensure the checkbox and label text are properly spaced */
.custom-checkbox-container label > input[type="checkbox"] {
  margin-right: 10px;
}

/* ====================== Responsive Tablets =============================== */

@media screen and (max-width: 1024px) {
  .container {
    width: var(--container-width-md);
  }

  h1 {
    font-size: 2.2rem;
  }

  h2 {
    font-size: 1.7rem;
  }

  h3 {
    font-size: 1.4rem;
  }

  h4 {
    font-size: 1.2rem;
  }

  .btn {
    padding: 0.4rem 2rem; /* Increases button size */
  }

  .container, .main-container {
    padding: 0 20px; /* Adds padding on the sides */
  }
  #spline-container::after {
    width: 70%; /* Width of the element */
  }
}

/* ====================== Responsive Phones =============================== */

@media screen and (max-width: 768px) {
  .container {
    width: var(--container-width-sm); /* Uses smaller container width */
  }

  .btn {
    padding: 0.4rem 1.5rem; /* Reduces button size for space */
  }

  /* Increase base text size for readability */
  body {
    font-size: 1rem; /* Increases text size */
  }

  /* Make images and sections adapt to smaller screens */
  img, .section {
    width: 100%;
    height: auto;
  }

  .hero-div h1 {
    font-size: 2.5rem; /* Adjusts hero text size */
  }

  .header__container, .about-section, .services-section, .portfolio-section {
    padding: 0 15px; /* Reduces padding */
    justify-content: center; /* Center the image container */
  }


  .header__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 20px; /* This adds space between your flex items */
  }

  .header__left, .header__right {
    width: 100%; /* This ensures each child takes full width */
    margin-bottom: 20px; /* This adds space between your flex items */
  }

  .hero-container {
    width: 100%;
    background-size: cover;
  background-attachment: scroll;
  }

  p {
    font-weight: 400;
    font-size: 1.2rem;
  }
}



@media screen and (max-width: 305px) {

  #spline-container::after {
    width: 100%; /* Adjust to 70% of the container's width */
  }
  
}

/* Container for the loading screen */
.loading-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background: linear-gradient(to right, var(--color-primary), #db016e); /* Gradient from purple to pink */
  }
  
  /* Spinner container */
  .spinner-container {
    position: relative;
    }
  
  /* Outer circle */
  .outer-circle {
    width: 16rem; /* Doubled from 8rem */
    height: 16rem; /* Doubled from 8rem */
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.2);
    animation: pulse 2s infinite;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
  }
  
  /* Inner circle */
  .inner-circle {
    width: 12rem; /* Doubled from 6rem */
    height: 12rem; /* Doubled from 6rem */
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.4);
    animation: ping 2s infinite;
    z-index: 1;
  }
  
  /* Orbit container */
  .orbit-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    border-radius: 50%;
  }
  
  /* Orbiting dots */
  .orbit-dot {
    position: absolute;
    width: 2rem; /* Doubled from 1rem */
    height: 2rem; /* Doubled from 1rem */
    background-color: white;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    z-index: 1;
  }
  
  /* Logo Image */
  .logo-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* Center the image */
    width: 160px; /* Doubled from 80px */
    height: auto;
    z-index: 2; /* Ensure it's above other elements */
    transition: var(--transition);
  }

  .logo-image:hover {
    transform: translate(-50%, -65%); /* Center the image */
  }
  
  /* Screen reader only */
  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }
  
  /* Enter Site button */
  .enter-button {
    background-color: white;
    color: #7e22ce; /* Equivalent to text-purple-600 */
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
  }
  
  .enter-button:hover {
    background-color: #e9d5ff; /* Equivalent to hover:bg-purple-100 */
  }
  
  /* Animations */
  @keyframes pulse {
    0%,
    100% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
  }
  
  @keyframes ping {
    0% {
      transform: scale(0.5);
      opacity: 1;
    }
    100% {
      transform: scale(1);
      opacity: 0;
    }
  }
  
  @keyframes orbit {
    from {
      transform: rotate(0deg) translateY(-96px) rotate(0deg); /* Adjusted translateY */
    }
    to {
      transform: rotate(360deg) translateY(-96px) rotate(-360deg); /* Adjusted translateY */
    }
  }
  
  @keyframes twinkle {
    0%,
    100% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
  }


.header-blog {
    text-align: center;
    margin-bottom: 8rem;
}

.blog-home-subtitle {
    font-size: 1rem;
    font-weight: 400;
    margin-top: -50px;
}

.blog-title {
    text-align: center;
    padding:  5rem 5rem 3rem ;
}

.blog-title-filtered {
    text-align: center;
    padding-top: 7rem;
    margin-bottom: 3rem;
}

.blog-title p {
    margin-top: 20px;
}


/* ================== Posts ============= */

.posts-container, .posts-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    margin-top: 3rem;
}

.posts-container {
    margin-top: 1rem;
}

.post {
    background: var(--color-tertiary);
    padding: 1rem;
    border-radius: 22px;
    padding-bottom: 2rem;
    transition: var(--transition);
    cursor: default;
    box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.3);
    margin-bottom: 0.4rem;
}

.post:hover {
    box-shadow: 4px 5px 2px rgba(0, 0, 0, 0.3);
}

.post-thumbnail {
    border-radius: 22px;
    overflow: hidden;
    height: auto;
}

.post-content {
    margin-top: 1.5rem; 
    color: var(--color-secondary);
}

.post-content h3 {
    margin: 1rem 0 0.6rem;
    color: var(--color-primary);
}

.post-footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 2rem;
}

.blog-text {
    font-size: 0.85rem;
}

.post-author {
    display: flex;
    gap: 1rem;
    align-items: flex-start;
    color: var(--color-secondary);
    
}

.post-author-avatar {
    width: 2.5rem;
    aspect-ratio: 1/1;
    border-radius: 50%;
    overflow: hidden;
}

.read-more-container {
    text-align: center;
    margin-top: 4rem;
}

.btn-postitem {
    text-align: center;
}

/* ================== Post Categories ============= */

.blog-categories-section {
    margin-top: 0rem;
    padding: 1rem;
}

.blog-categories {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    margin-bottom: 4rem;
    transition: var(--transition);
    color: var(--color-background);
    width: fit-content;
}

/* New rule to make each button fit its content */
.blog-categories li.btn {
    display: inline-flex;
    flex: 0 0 auto;
    width: auto;
}

.blog-categories a {
    color: var(--color-background);
    transition: var(--transition);
}

.blog-categories a:hover {
    color: var(--color-secondary);
}






/* ================== Post Detail ============= */


.post-detail-section {
    padding-top: 10rem;

}



.post-detail {
    width: var(--form-width);
    background: var(--color-tertiary);
    margin-inline: auto;
    border-radius: 22px;
    padding: 2rem;
}

.post-detail-btn {
    margin-top: 3rem;
    text-align: center;
    align-items: center;
    align-self: center;
}

.post-detail-header {
    display:flex;
    justify-content: space-between;
    margin-bottom: 1.5rem;
}

.post-detail-buttons {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.post-detail-thumbnail {
    margin: 1rem 0;
    height: fit-content;
    max-height: 30rem;
    overflow: hidden;
    border-radius: 22px;

}

.post-detail p {
    margin-bottom: 0.8rem;
}

.error-blog-not-found {
    text-align: center;
    font-size: 2rem;
    margin-top: 5rem;
    color: var(--color-primary);
}




/* ================== Register & Login ============= */


.form {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
}

input, select, textarea {
    width: 100%;
    padding: 0.6rem 1rem;
    border-radius: 22px;
    resize: none;
    border: 1.5px solid var(--color-complementary);
}
  

.form-error-message {
    background: var(--color-primary);
    color: var(--color-background);
    font-size: 0.8rem;
    padding: 0.5rem 1rem;
    border-radius: 22px;
    display: block;
    margin-bottom: 1rem;

}


.btn-submit {
    width: 30%;
    height: 40px;
    align-self: center;
    margin-top: 25px;
    text-align: center;
}

/* Styles for the form-small class */
.register small, .login small {
    display: block;
    text-align: center;
    margin-top: 30px;
  }

.register small a, .login small a {
    color: var(--color-primary)
}
  
.register, .login, .create-post{
    padding-top: 70px;
    width: 50%;
    margin-inline: auto;
    height: calc(100hv - 12rem);
    display: grid;
    place-items: center;
}





/* ================== Authors ============= */


.authors-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 3rem;
}

.authors {
    width: 90%;
    margin: 0 auto;
}

.author {
    background: var(--color-tertiary);
    padding: 1rem;
    border-radius: 22px;
    display: flex;
    gap: 10px;
    transition: var(--transition);
    box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.3);
    max-height: 7rem;

}

.author:hover {
    box-shadow: 4px 5px 2px rgba(0, 0, 0, 0.3);
}

.author-avatar {
    width: auto;
    aspect-ratio: 1/1;
    border-radius: 50%;
    border: 0.2rem solid var(--color-primary);
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.author-avatar img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.author-info p {
    margin-top: 1rem;
}


/* ================== User Profile ============= */

.profile-container {
    display: grid;
    place-items: center;
    text-align: center;
    padding-top: 8rem;
}

.profile-details {
    width: 100%;
}


.avatar-wrapper {
    width: 15rem;
    aspect-ratio: 1/1;
    position: relative;
    margin-inline: auto;
    margin-top: 1rem;
}

.profile-avatar {
    height: 100%;
    border-radius: 50%;
    border: 1rem solid var(--color-primary);
    overflow: hidden;
}

.avatar-form {
    height: 1rem;
}

.avatar-form input {
    visibility: hidden;
}

.avatar-form label, .profile-avatar-btn {
    background: var(--color-primary);
    color: var(--color-white);
    font-size: 1.2rem;
    position: absolute;
    right: 2rem;
    bottom: 1.2rem;
    width: 3.5rem;
    height: 3.5rem;
    display: grid;
    place-items: center;
    border-radius: 50%;
}

.profile-avatar-btn {
    cursor: pointer;

    padding: 0;
    font-size: 1.5rem;
    background-color: var(--color-secondary);
    color: var(--color-background);
}

.profile-details h1 {
    margin-top: 1.5rem;
}

.form.profile-form {
    margin-top: 3rem;
    margin-inline: auto;
    width: var(--form-width);
}
  
.btn-submit-profile {
    max-width: 100%;
    height: 40px;
    align-self: center;
    margin-top: 10px;
}



/* ================== Dashboard ============= */


.dashboard {
    padding-top: 3rem;
}
.dashboard-container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    align-items: center;
    max-width: 1200px; /* Adjust as needed for your layout */
    margin: 0 auto; /* Centers the container */
    padding: 0 1rem; /* Adds some padding on the sides for smaller screens */
}

.dashboard-post {
    display: flex;
    align-items: center;
    gap: 1.5rem; /* Increased gap for better spacing between elements */
    background: var(--color-secondary-transparent);
    padding: 1rem 2rem; /* Increased padding for better aesthetics */
    border-radius: 22px;
    box-shadow: 4px 5px 2px rgba(0, 0, 0, 0.1);
    transition: var(--transition);
    color: var(--color-white);
    width: 100%; /* Ensures the post takes full width of the container */
    max-width: 1000px; /* Limits the maximum width for better readability */
    margin: 0 auto; /* Centers the post within the container */
}


.dashboard-post:hover {
    transform: scale(1.01);
}

.dashboard-post-info {
    display: flex;
    align-items: center; /* Vertically centers the thumbnail and title */
    gap: 1.5rem;
    width: 100%;
}

.dashboard-post-info h4 {
    color: var(--color-white);
    margin: 0; /* Removes default margin */
    font-size: 1.2rem; /* Adjusts font size for better visibility */
    text-align: left; /* Aligns text to the left for readability */
    flex: 1; /* Allows the title to take up available space */
}


.dashboard-post-thumbnail {
    width: 80px; /* Adjusted width for better alignment */
    height: 80px; /* Maintains a square aspect ratio */
    border-radius: 20px; /* Makes the thumbnail circular */
    overflow: hidden;
    flex-shrink: 0; /* Prevents the thumbnail from shrinking */
}

.dashboard-post-thumbnail img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the image covers the container without distortion */
}


.dashboard-post-actions {
    display: flex;
    gap: 1rem;
}


/* ================== Create Post ============= */


.ql-editor {
    overflow: scroll;
    background: var(--color-white);
    height: 10rem !important; /* Increased height */
    color: var(--color-secondary); /* Text color */
  }
  
  .ql-container {
    height: 16rem; /* Ensure the container height matches */
    background: var(--color-background); /* Background color of the input text */
    border-radius: 22px;
  }
  
  .ql-toolbar.ql-snow {
    background: var(--color-secondary);
    color: var(--color-background);
    border-radius: 22px 22px 0 0;
  }
  
  .ql-toolbar.ql-snow .ql-picker-label {
    color: var(--color-white);
    font-size: 0.9rem;
  }
  
  .ql-toolbar.ql-snow .ql-picker-item {
    color: var(--color-white);
    font-size: 0.9rem;
  }
  
  .ql-toolbar.ql-snow .ql-stroke {
    stroke: var(--color-white);
  }
  
  .ql-toolbar.ql-snow .ql-fill {
    fill: var(--color-white);
  }
  
  .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
    background: var(--color-secondary);
  }
  
  .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-item.ql-selected {
    color: var(--color-background);
  }
  

  .custom-file-input-container {
    margin-top: 1rem; /* Add some margin to separate from other inputs */
  }
  
  .custom-file-input {

    font-size: 13px; /* Increase font size */
    border-radius: 25px; /* Apply border radius */
    display: inline-block;
    width: 100%;
    box-sizing: border-box;
    cursor: pointer;
  }
  

  .custom-file-input::file-selector-button {
    padding: 0.33rem 1.4rem;
    border: 3px solid transparent;
    padding: 12px; /* Increase padding */
    font-size: 16px; /* Increase font size */
    border-radius: 22px; /* Apply border radius */
    border: 1px solid var(--color-white); /* Optional: add a border */
    background-color: var(--color-white); /* Button background color */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5); /* Adjust shadow to match the design */
    transition: var(--transition);
  }  

  .custom-file-input::file-selector-button:hover {
    background: var(--color-background);
    color: var(--color-white);
    border-color: var(--color-white);
  }


  .edit-post {
    padding-top: 10rem;
  }


  .create-post h2, .edit-post h2 {
    font-size: 3rem;
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }


  


    /* ================== Tablet ============= */

@media screen and (max-width: 1100px) {

    .posts-container {
        grid-template-columns: repeat(2, 1fr);
        gap: 2rem;
        align-items: center;
        align-self: center;
    
    }
    
    .blog-categories {
        flex-direction: column;
    }
    
    .post-detail {
        width: 100%;
        padding: 2rem;
    }
    
    .post-detail-buttons {
        width: 40%;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }
    
    .register, .login, .create-post {
        width: 100%;
    }
    
    .btn-submit {
        width: 50%;
    }
    
    .form.profile-form {
        margin-top: 2.5rem;
        width: 100%;
    }
    
    .authors-container {
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;
        width: 100%;
    }
    
    
    .dashboard-post {
        flex-direction: column;
        padding: 2rem;
    }
    
    .dashboard-post-info {
        gap: 1.5rem;
    }
    
    .dashboard-post-thumbnail {
        width: 5rem;
        height: 5rem;
        border-radius: 50%;
    }

    .author-avatar {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        border: 0.3rem solid var(--color-primary);
    }
    

}


@media screen and (max-width: 840px) {

    .posts-container {
        grid-template-columns: 1fr;
        gap: 2rem;
        width: auto;
    
    }

}




  /* ================== Mobile ============= */

@media screen and (max-width: 800px) {

.posts-container {
    grid-template-columns: 1fr;
    gap: 2rem;
    width: auto;

}

.blog-categories {
    flex-direction: column;
}

.post-detail {
    width: 100%;
}

.post-detail-buttons {
    width: 40%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.register, .login, .create-post {
    width: 100%;
}

.btn-submit {
    width: 50%;
}

.form.profile-form {
    margin-top: 2.5rem;
    width: 100%;
}

.authors-container {
    grid-template-columns: 1fr;
    gap: 1rem;
    margin: 0 0 0 0;
}

.author-avatar {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border: 0.3rem solid var(--color-primary);
}



.authors {
    width: 80%;
    margin: 0 auto;
}


.dashboard-post {
    flex-direction: column;
    padding: 0rem;
}

.dashboard-post-info {
    gap: 1.5rem;
    flex-direction: column;
    
}

.dashboard-post-info h4 {
    font-size: 0.9rem;
    text-align: center;
    padding: 10px;
}

.dashboard-post-thumbnail {
    width: 100%;
    height: 100%;
    border-radius: 22%;
    padding: 10px;
}

.dashboard-post-actions .btn {
    padding: 10px 10px 10px 10px;
    margin-bottom: 20px;
}



}





  





.search-bar-container {
    position: relative;
    width: 100%;
    max-width: 300px; /* Adjust as needed */
    margin: 0 auto;
  }
  
  .search-icon {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    pointer-events: none;
  }
  
  .search-icon-svg {
    height: 16px;
    width: 16px;
    color: var(--color-primary); /* Equivalent to text-gray-400 */
  }
  
  .search-input {
    width: 100%;
    padding: 8px 12px 8px 36px; /* Adjusted padding for icon */
    border: 1px solid var(--color-complementary); /* Equivalent to border-gray-200 */
    border-radius: 22px; /* Equivalent to rounded-md */
    font-size: 14px;
    background-color: var(--color-background); /* Equivalent to bg-white */
    color: var(--color-secondary); /* Equivalent to text-gray-700 */
    transition: border-color 0.2s, box-shadow 0.2s;
  }
  
  .search-input::placeholder {
    color: var(--color-complementary); /* Equivalent to placeholder-gray-400 */
  }
  
  .search-input:focus {
    outline: none;
    border-color: var(--color-primary); /* Equivalent to focus:border-primary */
    box-shadow: 0 0 0 2px rgba(237, 32, 90, 0.103); /* Equivalent to focus:ring-primary */
  }
  
  .search-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    max-height: 240px; /* Equivalent to max-h-60 */
    overflow-y: auto;
    background-color: var(--color-background); /* Equivalent to bg-white */
    border: 1px solid rgba(0, 0, 0, 0.05); /* Equivalent to ring-1 ring-black ring-opacity-5 */
    border-radius: 22px; /* Equivalent to rounded-md */
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 
                0 4px 6px -2px rgba(0, 0, 0, 0.02); /* Equivalent to shadow-lg */
    z-index: 10;
    margin-top: 4px; /* Equivalent to mt-1 */
  }
  
  .search-dropdown ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .search-dropdown li {
    display: flex;
    align-items: center;
    padding: 9px 12px; /* Equivalent to px-3 py-1.5 */
    cursor: pointer;
    transition: background-color 0.2s;
    font-size: 14px;
  }
  
  .search-dropdown li:hover {
    background-color: var(--color-tertiary); /* Equivalent to hover:bg-gray-100 */
  }
  
  .dropdown-icon {
    height: 12px;
    width: 12px;
    color: var(--color-primary); /* Equivalent to text-gray-400 */
    margin-right: 8px; /* Equivalent to mr-2 */
  }
  
/* Container */
.theme-toggle {
    position: fixed;
    bottom: 1rem;
    left: 1rem;
    z-index: 1001; /* Ensure it's above other fixed elements */
  }
  
  /* Toggle Button */
  .toggle-button {
    background-color: #ffffff;
    color: var(--color-secondary); /* Gray-700 */
    border-radius: 50%;
    padding: 0.75rem;
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    cursor: pointer;
    outline: none;
    border: none;
  }
  
  .toggle-button:hover {
    background-color: var(--color-white-transparent);
  }
  
  .toggle-button:focus {
    box-shadow: 0 0 0 2px var(--color-primary-transparent); /* Blue-400 */
  }
  
  /* Icon */
  .icon {
    height: 1.5rem;
    width: 1.5rem;
  }
  
  /* Menu */
  .theme-menu {
    position: absolute;
    bottom: 100%;
    left: 0;
    margin-bottom: 0.5rem;
    opacity: 0;
    transform: translateY(1rem);
    transition: all 0.3s ease;
    pointer-events: none;
  }
  
  .theme-menu.open {
    opacity: 1;
    transform: translateY(0);
    pointer-events: auto;
  }
  
  /* Menu Content */
  .menu-content {
    background-color: var(--color-white);
    border-radius: 0.5rem;
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
    padding: 1rem;
    width: 12rem; /* 256px */
  }
  
  /* Menu Title */
  .menu-title {
    font-size: 1rem; /* 18px */
    margin-bottom: 0.75rem;
    font-family: 'Manjari', sans-serif;
    color: var(--color-secondary); /* Gray-700 */
  }
  
  /* Theme Options */
  .theme-options {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    
  }
  
  /* Theme Button */
  .theme-button {
    width: 80%;
    text-align: left;
    padding: 0.5rem 1rem;
    border-radius: 22px; /* 6px */
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    cursor: pointer;
    outline: none;
    border: none;
    font-weight: 500;
    color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: relative;
  }
  
  .theme-button:hover {
    transform: scale(1.05);
  }
  
  .theme-button:focus {
    box-shadow: 0 0 0 2px var(--color-primary);
  }
  
  /* Active Theme Button Indicator */
  .theme-button.active::after {
    content: '✓';
    position: absolute;
    right: 1rem;
    bottom: 0.6rem;
    color: var(--color-secondary);

  }

  .theme-button p {
    color: var(--color-secondary);
    padding: 0.2rem;
  }
  
  /* Theme-specific styles */
  .theme-button.normal {
    background: linear-gradient(to right, var(--color-primary), var(--color-primary-transparent)); /* Light Blue Gradient */
  }
  
  .theme-button.light-blue {
    background: linear-gradient(to right, lightblue, var(--color-complementary)); /* Light Blue Gradient */
  }
  
  .theme-button.yellowish-beige {
    background: linear-gradient(to right, rgb(231, 231, 38), beige); /* Yellowish Beige Gradient */
  }

  .theme-button.green {
    background: linear-gradient(to right, rgb(84, 190, 171), rgba(109, 201, 172, 0.758)); /* Yellowish Beige Gradient */
  }
  
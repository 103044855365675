/* src/css/cookieConsent.css */

.cookie-consent {
    position: fixed;
    bottom: 20px;
    left: 20px;
    right: 20px;
    background-color: var(--color-primary-transparent);
    color: #ffffff;
    padding: 30px 30px;
    border-radius: 22px;
    z-index: 1000;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
  }
  
  .cookie-consent__content p {
    margin: 0;
    margin-bottom: 20px;
  }
  
  .cookie-consent__link {
    color: var(--color-secondary);
    margin-left: 5px;
    text-decoration: underline;
  }
  
  .cookie-consent__buttons {
    display: flex;
    gap: 10px;
  }
  
  .cookie-consent__button {
    background-color: var(--color-white);
    color: var(--color-primary);
    border: none;
    padding: 8px 15px;
    border-radius: 22px;
    cursor: pointer;
    font-size: 13px;
    font-weight: 600;
  }
  
  .cookie-consent__button.decline {
    background-color: var(--color-secondary);
    color: #ffffff;
  }
  
  .cookie-consent__button:hover {
    opacity: 0.9;
  }
  
.footer {
    padding: 50px 0;
    background-size: cover;
    background-position: center;
    color: var(--color-white);
    margin-top: 3rem;
  }
  
  .footer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .footer-top {
    text-align: center;
    margin-bottom: 50px;
  }

  .footer-top img {
    max-width: 150px;
    margin-bottom: 20px;
    margin: 0 auto;
  }
  
  .footer-logo {
    max-width: 100px;
    margin-bottom: 20px;
  }
  
  .footer-quote {
    font-size: 1.5rem;
    opacity: 1;
    color: var(--color-white);
  }
  
  .footer-bottom {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 50px;
  }
  
  .footer-column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .footer-column h4 {
    margin-bottom: 20px;
    font-size: 1.2rem;
    color: var(--color-white);
  }
  
  .footer-column .socials-container {
    display: flex;
    flex-direction: column;
  }
  
  .footer-column .social-row {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
  }
  
  .footer-column .social-row img,
  .footer-column .location-icon {
    width: 24px; /* Set a fixed width for icons */
    height: 24px; /* Set a fixed height for icons */
    margin-right: 10px;
    object-fit: contain; /* Maintain the aspect ratio of icons */
  }

  .social-row img:hover {
    transform: scale(1.4);
  }
  
  .footer-column .footer-link {
    color: var(--color-white);
    text-decoration: none;
    transition: var(--transition);
  }
  
  .footer-column .footer-link:hover {
    color: var(--color-white);
    opacity: 0.4;
  }
  
  .footer-copy {
    text-align: center;
    font-size: 0.9rem;
    opacity: 0.8;
  }
  
  .footer-copy-designed-by-synaps {
    color: var(--color-white);
    font-size: 20px;
    text-decoration: underline;
    transition: var(--transition);
  }
  
  .footer-copy-designed-by-synaps:hover {
    color: var(--color-secondary);
  }
  

  .privacy-policy {
    transition: var(--transition);
    color: var(--color-white);
    opacity: 50%;
    font-size: 15px;
    margin-top: 40px;
  }

  .privacy-policy:hover {
    color: var(--color-secondary);
  }
  

  

  @media screen and (max-width: 768px) {

    .footer-top, .footer-bottom {
      flex-direction: column;

    }
    
    .footer-column {
      width: 100%;
      margin-right: 0;
      margin-bottom: 20px; /* Adjust as needed for spacing between mobile columns */
      text-align: center;
      align-items: center;
      margin: 0 auto;
      padding: 20px 0;
    }
    
  }
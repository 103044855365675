/* Sidebar container */
.latest-sidebar {
    position: fixed;
    right: 0;
    top: 14.4vh;
    height: 85.6vh;
    background-color: var(--color-primary-transparent-sidebar);
    backdrop-filter: blur(30px);
    border-left: 1px solid var(--color-secondary-transparent-sidebar);
    overflow: hidden;
    z-index: 300;
    border-radius: 20px 0 0 0;
    color: var(--color-white);
  }
  
  /* Flex container inside the sidebar */
  .sidebar-container {
    display: flex;
    height: 100%;
  }
  
  /* Icons column */
  .sidebar-icons {
    width: 60px;
    border-right: 1px solid var(--color-secondary-transparent-sidebar);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px 0;
    gap: 12px;
  }
  
  /* Toggle button */
  .sidebar-toggle-button {
    padding: 8px;
    border: none;
    border-radius: 8px;
    background-color: transparent;
    cursor: pointer;
    position: relative;
    transition: background-color 0.2s ease;
    color: var(--color-white);
  }
  
  .sidebar-toggle-button:hover {
    background-color: var(--color-primary-transparent-sidebar);
  }
  
  /* Chevron icon with rotation */
  .chevron-icon {
    transition: transform 0.2s ease;
  }
  
  .chevron-icon.rotated {
    transform: rotate(180deg);
  }
  
  /* Generic icon button (for categories) */
  .sidebar-icon-button {
    padding: 8px;
    border: none;
    border-radius: 8px;
    background-color: transparent;
    cursor: pointer;
    transition: background-color 0.2s ease, color 0.2s ease;
    color: var(--color-white);
  }
  
  .sidebar-icon-button:hover,
  .sidebar-icon-button:focus {
    color: var(--color-secondary);
  }
  
  /* Active state for icon button */
  .sidebar-icon-button.active {
    color: var(--color-white);
  }
  
  /* Small icon dimensions */
  .icon-small {
    width: 20px;
    height: 20px;
  }
  
  /* Content area */
  .sidebar-content {
    flex: 1;
    overflow-y: auto;
    padding: 16px;
  }
  
  /* Section headings */
  .section-heading {
    font-weight: 600;
    font-size: 18px;
    margin: 0;
  }
  
  .section-heading--mb {
    margin-bottom: 16px;
  }
  
  /* Grid with gap */
  .grid-gap-4 {
    display: grid;
    gap: 16px;
  }
  
  /* Product Card */
  .product-card {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 12px;
    border-radius: 8px;
    transition: background-color 0.2s ease;
    cursor: default;
  }
  
  .product-card:hover {
    background-color: var(--color-primary-transparent-sidebar-hover);
  }
  
  /* Product image and title */
  .product-image-container {
    cursor: pointer;
  }
  
  .product-image {
    width: 100%;
    height: 128px;
    border-radius: 4px;
    object-fit: cover;
    margin-bottom: 8px;
  }
  
  .product-title {
    font-weight: 500;
    margin: 0;
    cursor: pointer;
  }
  
  .product-title:hover,
  .product-title:focus {
    color: var(--color-secondary);
    text-decoration: underline;
  }
  
  /* Product description */
  .product-description {
    font-size: 14px;
    margin: 0;
  }
  

  /* Custom scrollbar for sidebar-content */
  .sidebar-content::-webkit-scrollbar {
    width: 8px;
  }
  
  .sidebar-content::-webkit-scrollbar-track {
    background: var(--color-primary-transparent-sidebar);
    border-radius: 8px;
  }
  
  .sidebar-content::-webkit-scrollbar-thumb {
    background: var(--color-primary-transparent);
    border-radius: 8px;
  }
  
  /* Mobile view adjustments */
  @media (max-width: 1024px) {
    .latest-sidebar {
      bottom: 0;
      max-height: 480px;
      top: auto;
      right: 0;
      /* The mobile CSS positions the sidebar in the bottom-right;
         the width animation will expand it from right to left */
      border-radius: 20px 20px 0 20px;
    }
  }
  

/* Close button inside the icons column */
.sidebar-icons .sidebar-close-button {
  margin-top: auto;
  margin-bottom: 16px;
  padding: 8px;
  border: none;
  background-color: transparent;
  color: var(--color-white);
  cursor: pointer;
  font-size: 18px;
  border-radius: 10px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s ease;
}
.sidebar-icons .sidebar-close-button:hover {
  background-color: var(--color-primary-transparent-sidebar-hover);
}


/* Open button in bottom right corner */
.sidebar-open-button {
  position: fixed;
  bottom: 16px;
  right: 16px;
  padding: 12px;
  border: none;
  background-color: var(--color-white);
  color: var(--color-secondary);
  cursor: pointer;
  font-size: 18px;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: background-color 0.2s ease;
  z-index: 400;
}
.sidebar-open-button:hover {
  background-color: var(--color-white-transparent);
}
